import {ref, watch, computed} from '@vue/composition-api'
import store from '@/store'

// Notification
import {useToast} from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {isEmpty} from "@core/utils/filter";

export default function datacentersalesptUseList() {
    // Use toast
    const toast = useToast()

    const refListTable = ref(null)

    // Table Handlers
    const tableColumns = [
        {key: 'id', label: 'ID', sortable: true},
        {key: 'store_company_id', label: '平台', sortable: true},
        {key: 'store_name', label: '店铺名称', sortable: true},
        {key: 'relation_name', label: '外部仓库', sortable: true},
        {key: 'warehouse_name', label: '仓库名称', sortable: true},
        {key: 'purchase_team_name', label: '销售权团队名称', sortable: true},
        // { key: 'state', label: '启用', sortable: true },
        {key: 'create_time', label: '添加时间', sortable: true},
        {key: 'creator', label: '添加人ID', sortable: true},
        // { key: 'update_time', label: '更新时间', sortable: true },
        // { key: 'updator', label: '更新人ID', sortable: true },
        {key: 'actions', label: '操作'},
    ]

    const start = ref(1)
    const limit = ref(10)
    const limitOptions = [10, 25, 50, 100]
    const listTotals = ref(0)
    const searchKey = ref('')
    const orderBy = ref('pt_id')
    const isSortDirDesc = ref(true)
    const condition = ref({})

    const dataMeta = computed(() => {
        const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
        return {
            from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
            to: limit.value * (start.value - 1) + localItemsCount,
            of: listTotals.value,
        }
    })

    const refetchData = () => {
        refListTable.value.refresh()
    }

    watch([start, limit, searchKey], () => {
        refetchData()
    })

    const searchList = (ctx, callback) => {
        store
            .dispatch('datacentersalespt/search', {
                search_key: searchKey.value,
                start: start.value,
                limit: limit.value,
                order_by: orderBy.value,
                order_desc: isSortDirDesc.value === true ? 'desc' : '',
                store_company_id: condition.value.companyId,
                store_id: condition.value.storeId,
                warehouse_id: condition.value.warehouseId,
                relation_name: condition.value.relation_name,
                purchase_team_id: isEmpty(condition.value.purchaseTeam) ? null : condition.value.purchaseTeam.value,
            })
            .then(response => {
                const data = response.data.data
                const list = data.ext.list
                listTotals.value = data.ext.totals

                callback(list)
            })
            .catch((e) => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: '场次销售数据仓库与销售权配置表列表获取错误',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                    },
                })
            })
    }

    // *===============================================---*
    // *--------- UI ---------------------------------------*
    // *===============================================---*

    return {
        searchList,
        tableColumns,
        limit,
        start,
        listTotals: listTotals,
        dataMeta,
        limitOptions,
        searchKey,
        orderBy,
        isSortDirDesc,
        refListTable,
        condition,
        refetchData,

        // Extra Filters
    }
}
