<template>
  <div>
      <b-input-group
          label-cols="3"
          label-cols-lg="3"
          :prepend="label+':'"
          size="sm"
      >
        <b-form-input
            v-if="type==='input'"
            class="d-inline-block"
            @click="showSingleModal(modalName)"
            :value="value"
            :placeholder="placeholder"
            :disabled="disabled"
        />
        <flat-pickr
            v-if="type==='time'"
            class="form-control"
            :config="{ mode: 'range',maxDate: toTime(maxTime)}"
            @input="emit($event)"
            :value="value"
            allowInput="true"
            enableTime="true"
        />
        <flat-pickr
                v-if="type==='month'"
                class="form-control"
                :config="{mode: 'range', enableTime: true,dateFormat: 'Y-m',enableTime:false,maxDate: toTime(maxTime)}"
                @input="emit($event)"
                :value="value"
        />
        <flat-pickr
            v-if="type==='onlyMonth'"
            class="form-control"
            :config="{ mode: 'range',plugins:[fp]}"
            @input="emit($event)"
            :value="value"
        />
        <flat-pickr
            v-if="type==='dataAndTime'"
            class="form-control"
            :config="{ enableTime: true,dateFormat: 'Y-m-d H:i'}"
            @input="emit($event)"
            :value="value"
        />
        <flat-pickr
            v-if="type==='data'"
            class="form-control"
            @input="emit($event)"
            :value="value"
        />

        <b-input-group-append>
          <b-button variant="outline-primary" @click="del" :disabled="buttonDisable">
            删除
          </b-button>
        </b-input-group-append>
      </b-input-group>


    <b-modal
        id="modal-single"
        ok-only
        ok-title="确认"
        @ok="onSelectSingle"
        cancel-title="取消"
        centered
        size="lg"
        :title="'请选择'+modalName"
        ref="singleModal"
    >

      <company-select
          ref="companySelect" :all="(modalName==='终端客户和渠道'||modalName==='终端客户')?1:0" :isAllState="true" :storeId="storeId" :companyType="companyType" v-if="modalName === '销售主体' || modalName ==='渠道方'|| modalName ==='供应商'|| modalName ==='终端客户'||modalName === '客户名称' ||modalName==='仓库方'||modalName==='终端客户和渠道' "
      >
      </company-select>

      <product-select
          ref="productSelect"  v-else-if="modalName === '商品' "
      >
      </product-select>

      <product-select
          ref="productCodeSelect"  v-else-if="modalName === '商品加69码' "
      >
      </product-select>

      <product-label-list
          ref="productLabelSelect" v-else-if="modalName === '标签' "
      >
      </product-label-list>


<!--   yang   -->


      <!-- :userDepartment="userDepartment"  判断负责人所属部门-->
      <user-select
          ref="userSelect" :selectMode="selectMode" :userDepartment="userDepartment" v-else-if="modalName === '销售员' || modalName ==='创建人' || modalName==='责任人' "
      >
      </user-select>

      <store-list
          ref="storeList" :all="1" :company_id="companyId" v-else-if=" modalName === '店铺' ||modalName === '团队' "
      >
      </store-list>

      <store-warehouse-list
              ref="storeWarehouseList" v-else-if="modalName === '仓库店铺' "
      >
      </store-warehouse-list>

      <warehouse-list
          ref="warehouseSelect" v-else-if="modalName === '仓库'"
      >
      </warehouse-list>

      <area-model-list
          ref="areaSelect" v-else-if="modalName === '城市'"
      >
      </area-model-list>


    </b-modal>

  </div>

</template>

<script>

import XyGroup from './XyGroup'
import flatPickr from 'vue-flatpickr-component'
import {BInputGroup, BFormInput, BInputGroupAppend, BInputGroupPrepend, BRow, BCol, BButton,VBModal} from 'bootstrap-vue'
import {onMounted, reactive, toRefs} from "@vue/composition-api";
import store from "@/store";
import vSelect from 'vue-select'
import CompanySelect from "@/views/apps/company/CompanySelect"
import {toTime, toDate, toMonth} from '@core/utils/dayjs/utils'
import UserSelect from '@/views/apps/user/user-modal/UserSelect'
import StoreList from "@/views/apps/store/store-modal/StoreListSelect";
import StoreWarehouseList from "@/views/apps/store/store-warehouse/StoreList";
import WarehouseList from "@/views/apps/warehouse/warehouse-modal/WarehouseList";
import ProductSelect from '@/views/apps/product/product-salesorder/ProductSelect'
import {avatarText, getCode, getCodeColor, getCodeLabel, getCodeOptions, isEmpty} from "@core/utils/filter";
import AreaModelList from "@/views/apps/area/model/AreaModelList";
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect';
import ProductLabelList from "@/views/apps/productlabel/productlabel_modal/ProductLabelList";

export default {
  components: {
    ProductLabelList,
    AreaModelList,
    XyGroup,
    flatPickr,
    monthSelectPlugin,
    BInputGroup,
    VBModal,
    BFormInput,
    BInputGroupAppend,
    BInputGroupPrepend,
    BRow,
    BCol,
    BButton,
    vSelect,
    CompanySelect,
    UserSelect,
    StoreList,
    WarehouseList,
    ProductSelect,
    StoreWarehouseList,
  },
  data(){
    return{
      data:{},
      companyType: '',
      // modalName: '',
      userDepartment: '',
    }
  },
  props: {
    //前面的显示文字
    label:{
      type: String,
      default: ''
    },
    //类型:input:普通输入框,time:日期选择框
    type: {
      type: String,
      default: 'input'
    },
    //显示的值
    value: {
      type:String,
      default:''
    },
    //弹窗的名字
    modalName:{
      type:String,
      default:''
    },
    //单选多选
    selectMode:{
      type:String,
      default:'single'
    },
    // 店铺/团队Id
    storeId:{
      type:Number,
      default:null
    },
    //终端客户id
    companyId:{
      type:Number,
      default:null
    },
    //(数组)需要赋值的值(第一个值是名称,第二个值是id)
    params:{

    },
    //输入框提示字符
    placeholder:{
      type:String,
      default:''
    },
    //最大时间
    maxTime:{
      type:Number,
      default:null
    },
    buttonDisable:{
      type:Boolean,
      default:false
    },
    disabled:{
      type:Boolean,
      default:false
    },
  },
  methods:{
    del:function(){
      this.$emit('change', null)
    },
    emit:function(event){
      for (let i = 0; i < this.params.length; i++) {
        this.data[this.params[i]] = event
      }
      this.$emit('change',this.data)
    },
    showSingleModal(modalName) {
      if (modalName === '渠道方') {
        this.companyType = 'ChannelCustomer'
      } else if (modalName === '销售主体'|| modalName ==='主体') {
        this.companyType = 'Group'
      }
      else if (modalName === '供应商') {
        this.companyType = 'Supplier'
      }
      else if (modalName === '终端客户') {
        this.companyType = 'OffSalesChannelCustomer'
      }
      else if (modalName === '客户名称') {
        this.companyType = 'SalesCustomer'
      }
      else if (modalName === '仓库方') {
        //this.companyType = 'ChannelCustomer'
        this.companyType = 'WareHouse'
      }
      else if (modalName === '终端客户和渠道') {
        this.companyType = 'OffChannelAndChannel'
      }
      this.$refs['singleModal'].show()
    },
    onSelectSingle() {
      let data = {}
      switch (this.modalName) {
        case '渠道方': {
          data = this.$refs.companySelect.getSelected()
          if (data.length > 0) {
            this.data[this.params[0]] = data[0].company_name;
            this.data[this.params[1]] = data[0].company_id;
            this.$forceUpdate()
            this.$emit('change',this.data)
          }
          break
        }
        case '客户名称': {
          data = this.$refs.companySelect.getSelected()
          if (data.length > 0) {
            this.data[this.params[0]] = data[0].company_name;
            this.data[this.params[1]] = data[0].company_id;
            this.$forceUpdate()
            this.$emit('change',this.data)
          }
          break
        }
        case '终端客户': {
          data = this.$refs.companySelect.getSelected()
          if (data.length > 0) {
            this.data[this.params[0]] = data[0].company_name;
            this.data[this.params[1]] = data[0].company_id;
            this.$forceUpdate()
            this.$emit('change',this.data)
          }
          break
        }
        case '销售主体': {
          data = this.$refs.companySelect.getSelected()
          if (data.length > 0) {
            this.data[this.params[0]] = data[0].company_name;
            this.data[this.params[1]] = data[0].company_id;
            this.$forceUpdate()
            this.$emit('change',this.data)
          }
          break
        }
        case '供应商': {
          data = this.$refs.companySelect.getSelected()
          if (data.length > 0) {
            this.data[this.params[0]] = data[0].company_name;
            this.data[this.params[1]] = data[0].company_id;
            this.$forceUpdate()
            this.$emit('change',this.data)
          }
          break
        }
        case '销售员': {
          data = this.$refs.userSelect.getSelected()
          if (data.length > 0) {
            this.data[this.params[0]] = data[0].full_name;
            this.data[this.params[1]] = data[0].user_id;
            this.$forceUpdate()
            this.$emit('change',this.data)
          }
          if(data.length>1 &&this.selectMode==='multi'){
            let fullNameArr = []
            let userIdArr = []
            data.forEach(item=>{
              fullNameArr.push(item.full_name)
              userIdArr.push(item.user_id)
            })
            this.data[this.params[0]] = fullNameArr.join(",");
            this.data[this.params[1]] = userIdArr.join(",");
            this.$forceUpdate()
            this.$emit('change',this.data)
          }
          break;
        }
        case '创建人': {
          data = this.$refs.userSelect.getSelected()
          if (data.length > 0) {
            this.data[this.params[0]] = data[0].full_name;
            this.data[this.params[1]] = data[0].user_id;
            this.$forceUpdate()
            this.$emit('change',this.data)
          }
          break;
        }
        case '责任人': {
          data = this.$refs.userSelect.getSelected()
          if (data.length > 0) {
            this.data[this.params[0]] = data[0].full_name;
            this.data[this.params[1]] = data[0].user_id;
            this.$forceUpdate()
            this.$emit('change',this.data)
          }
          break;
        }
        case '店铺': {
          data = this.$refs.storeList.getSelected()
          if (data.length > 0) {
            this.data[this.params[0]] = data[0].store_name;
            this.data[this.params[1]] = data[0].store_id;
            this.$forceUpdate()
            this.$emit('change',this.data)
          }
          break;
        }
        case '团队': {
          data = this.$refs.storeList.getSelected()
          if (data.length > 0) {
            this.data[this.params[0]] = data[0].store_name;
            this.data[this.params[1]] = data[0].store_id;
            this.$forceUpdate()
            this.$emit('change',this.data)
          }
          break;
        }
        case '仓库店铺': {
          data = this.$refs.storeWarehouseList.getSelected()
          if (data.length > 0) {
            this.data[this.params[0]] = data[0].store_name;
            this.data[this.params[1]] = data[0].store_id;
            this.$forceUpdate()
            this.$emit('change',this.data)
          }
          break;
        }
        case '仓库': {
          data = this.$refs.warehouseSelect.getSelected()
          if (data.length > 0) {
            this.data[this.params[0]] = data[0].warehouse_name;
            this.data[this.params[1]] = data[0].warehouse_id;
            this.$forceUpdate()
            this.$emit('change',this.data)
          }
          break;
        }
        case '仓库方': {
          data = this.$refs.companySelect.getSelected()
          if (data.length > 0) {
            this.data[this.params[0]] = data[0].company_name;
            this.data[this.params[1]] = data[0].company_id;
            this.$forceUpdate()
            this.$emit('change',this.data)
          }
          break;
        }
        case '商品': {
          data = this.$refs.productSelect.getSelected()
          if (data.length > 0) {
            this.data[this.params[0]] = data[0].name;
            this.data[this.params[1]] = data[0].product_id;
            this.$forceUpdate()
            this.$emit('change',this.data)
          }
          break;
        }
        case '商品加69码': {
          data = this.$refs.productCodeSelect.getSelected()
          if (data.length > 0) {
            this.data[this.params[0]] = data[0].name;
            this.data[this.params[1]] = data[0].product_id;
            this.data[this.params[2]] = data[0].code;
            this.$forceUpdate()
            this.$emit('change',this.data)
          }
          break;
        }
        case '终端客户和渠道': {
          data = this.$refs.companySelect.getSelected()
          if (data.length > 0) {
            this.data[this.params[0]] = data[0].company_name;
            this.data[this.params[1]] = data[0].company_id;
            this.$forceUpdate()
            this.$emit('change',this.data)
          }
          break
        }
        case '城市': {
          data = this.$refs.areaSelect.getSelected()
          if (data.length > 0) {
            this.data[this.params[0]] = data[0].area_name;
            this.data[this.params[1]] = data[0].area_id;
            this.$forceUpdate()
            this.$emit('change',this.data)
          }
          break
        }
        case '标签': {
          data = this.$refs.productLabelSelect.getSelected()
          if (data.length > 0) {
            this.data[this.params[0]] = data[0].label_name;
            this.data[this.params[1]] = data[0].label_id;
            this.$forceUpdate()
            this.$emit('change',this.data)
          }
          break
        }
        default: {
          break
        }
      }
    },
  },
  setup(){
    const fp = new monthSelectPlugin({
      shorthand: false,
      dateFormat: 'Y-F',
      altFormat: 'Y-F',
    })

    return {
      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      toDate,
      isEmpty,
      // page
      toTime,
      fp,

    }
  }
}
</script>
